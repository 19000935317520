import * as React from "react";
import * as styles from "./index.module.css";

import { FunctionComponent, useCallback, useState } from "react";

import { Button } from "@chakra-ui/react";
import ContactForm from "../components/Contact/ContactForm";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import { navigate } from "gatsby";
import useAuth from "../components/useAuth";

const Home: FunctionComponent = () => {
  const { loggedIn, user } = useAuth();

  const onButton4Click = useCallback(() => {
    window.open("/about-us");
  }, []);

  const onButton5Click = useCallback(() => {
    window.open("/shop");
  }, []);

  const onButton6Click = useCallback(() => {
    navigate("/shop/category/medical-devices");
  }, []);

  const onButto7Click = useCallback(() => {
    navigate("/shop/category/medical-conveyance-aid");
  }, []);

  const onButto8Click = useCallback(() => {
    navigate("/shop/category/disposable-practice-supply");
  }, []);

  const onButto9Click = useCallback(() => {
    navigate("/shop/category/ppe");
  }, []);

  const onButto10Click = useCallback(() => {
    navigate("/shop/category/practice-instruments");
  }, []);

  const onButto11Click = useCallback(() => {
    navigate("/shop/category/practice-supply");
  }, []);

  return (
    <Layout>
      <div className={styles.home}>
        <div className={styles.heroSection}>
          <div className={styles.heroContent}>
            <div className={styles.heroText}>
              <div className={styles.title}>
                <div className={styles.display}>
                  Cost-effective options for all your medical product
                  requirements
                </div>
              </div>
              <div className={styles.p}>
                <div className={styles.caption}>
                  CDN Health offers an extensive range of health and wellness
                  products, such as Personal Protective Equipment (PPE), medical
                  devices, canes, walkers, cushions, and more. Our wide
                  selection of products enables you to support your patients
                  better.
                </div>
              </div>
            </div>
            <div className={styles.heroActions}>
              <Button
                className={styles.button}
                variant="outline"
                colorScheme="cdicyan"
                size="lg"
                onClick={onButton4Click}>
                Learn More
              </Button>
              <Button
                variant="solidWhite"
                colorScheme="cdicyan"
                size="lg"
                onClick={onButton5Click}>
                Start Shopping
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.cardsSection}>
          <div className={styles.sectionTitle}>
            <h2 className={styles.display1}>What We Offer</h2>
          </div>
          <div className={styles.cardsRow}>
            <div className={styles.cardSlot}>
              <div className={styles.card}>
                <div className={styles.cardContent}>
                  <div className={styles.title}>
                    <b className={styles.label}>Wholesale Medical Equipment</b>
                  </div>
                  <div className={styles.p1}>
                    <div className={styles.caption1}>
                      CDN Health provides healthcare organizations, spas,
                      pharmaceutical stores, private practices, and wholesalers
                      with the most competitive prices for the medical equipment
                      and devices.
                    </div>
                  </div>
                  <Button
                    className={styles.button}
                    variant="solid"
                    colorScheme="cdicyan"
                    onClick={onButton4Click}>
                    Learn More
                  </Button>
                </div>
                <div className={styles.cardImg}>
                  <img
                    className={styles.depositphotos349498906XL1Icon}
                    alt=""
                    src="../depositphotos-349498906-xl-1@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.categoriesSection}>
          <div className={styles.sectionTitle}>
            <div className={styles.display2}>Product Categories</div>
          </div>
          <div className={styles.categoryCards}>
            <div className={styles.catCardRow}>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="../categoryCard.webp"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>Medical devices</div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      colorScheme="cdicyan"
                      backgroundColor="#0079C1"
                      onClick={onButton6Click}>
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="../categoryCard-1.webp"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>Medical Conveyance Aid</div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      backgroundColor="#0079C1"
                      onClick={onButto7Click}>
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="../categoryCard-2.webp"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>
                      Disposable Practice Supply
                    </div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      colorScheme="cdicyan"
                      onClick={onButto8Click}>
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.catCardRow}>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="../categoryCard-3.webp"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>
                      Personal Protective Equipment (PPE)
                    </div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      colorScheme="cdicyan"
                      onClick={onButto9Click}>
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="../categoryCard-4.webp"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>Practice Instruments</div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      colorScheme="cdicyan"
                      onClick={onButto10Click}>
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="../categoryCard-5.webp"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label1}>Practice Supply</div>
                    <Button
                      className={styles.button4}
                      variant="solid"
                      colorScheme="cdicyan"
                      onClick={onButto11Click}>
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.whySection}>
          <div className={styles.whyImg}>
            <img
              className={styles.depositphotos349498186S1Icon}
              alt=""
              src="../whyImg.webp"
            />
          </div>
          <div className={styles.features}>
            <div className={styles.sectionTitle2}>
              <h2 className={styles.display3}>Why Us</h2>
            </div>
            <div className={styles.featuresRow}>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../medicinesyrup.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Extensive Array of Products</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      Through our wide-ranging network of suppliers, we
                      guarantee a diverse range of medical equipment and
                      products available for our clients to select from.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../premiumrights.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Competitive Pricing</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      Our remarkably low prices distinguish our company. With
                      the support of our global network of partners, we can
                      offer medical products that meet both your budgetary needs
                      and those of your patients or customers.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot2}>
                <div className={styles.feature2}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../ship.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Quick Shipping</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      Our clients depend on us for prompt delivery of the
                      products essential for their medical clinics and
                      pharmaceutical supply stores. Therefore, we guarantee
                      timely and hassle-free delivery of all products.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.featuresRow}>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../shieldcheck.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Reputable Brands</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      We exclusively carry genuine, top-notch products,
                      including well-known brand names that are familiar and
                      valued by your patients.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../headset.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Excellent Customer Care</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      We understand that exemplary and dependable service is
                      crucial for any thriving business. Our team is dedicated
                      to ensuring all our customers are content with both their
                      products and the service provided.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot}>
                <div className={styles.feature2}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../world.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>International Presence</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      Our products are procured from suppliers worldwide,
                      allowing us to deliver to customers in any country with
                      our extensive global network.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.contactSection}
          data-scroll-to="contactSectionContainer">
          <div className={styles.contactContent} id="contactSectionContainer">
            <ContactForm></ContactForm>
          </div>
          <div className={styles.contactDetailsSignIn}>
            <div className={styles.contactDetails}>
              <div className={styles.logo3}>
                <img
                  className={styles.cdihealthlogoIcon}
                  alt=""
                  src="../Logo-White.svg"
                />
              </div>
              <div className={styles.title1}>
                <div className={styles.display5}>Need Anything?</div>
              </div>
              <div className={styles.visibleDetails}>
                <div className={styles.details}>
                  <div className={styles.label19}>Call us:</div>
                  <b className={styles.label7}>888-306-5371 </b>
                </div>
                <div className={styles.details}>
                  <div className={styles.label19}>Email us:</div>
                  <b className={styles.label7}>
                    info@cdnhealth.net <br></br>
                  </b>
                </div>

                {!loggedIn ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <div className={styles.details}>
                      <div className={styles.label19}>Address:</div>
                      <b className={styles.label7}>
                         152/NO 9, NAXXAR ROAD SAN GWANN, SGN 9030, MALTA
                      </b>
                    </div>
                  </>
                )}

                <div className={styles.details}>
                  <div className={styles.label19}>Monday to Friday</div>
                  <b className={styles.label7}>9AM - 4PM Central</b>
                </div>
              </div>

              <div className={styles.label25}>
                {loggedIn ? (
                  <></>
                ) : (
                  <div className={styles.label26}>
                    Please sign in for address
                  </div>
                )}
              </div>
              <div className={styles.ccs}>
                <img className={styles.visaIcon} alt="" src="../visa2.svg" />
                <img
                  className={styles.visaIcon}
                  alt=""
                  src="../mastercard2.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

export const Head = () => (
  <SEO
    title="CDN Health"
    description="Cost-effective options for all your medical product requirements"
  />
);
